
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import {
        IOrganization,
        IVeterinarian,
    } from '@/types';

    @Component<Breadcrumbs>({})
    export default class Breadcrumbs extends Vue {
        @Prop({
            type: Object,
            required: true,
        })
        public organization!: IOrganization;

        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian!: IVeterinarian;

        get searchQuery() {
            return {
                latitude: this.organization.location?.latitude,
                longitude: this.organization.location?.longitude,
                range: 'any',
                sort_by: 'relevance',
                page: 1,
                per_page: 10,
            };
        }
    }
