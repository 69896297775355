
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import {
        IOrganization,
    } from '@/types';

    @Component<OrganizationThumbs>({})
    export default class OrganizationThumbs extends Vue {
        public imageCarouselIsOpen: boolean =  false;

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;

    }
