
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import HereMap from '@/components/HereMap.vue';

    import {
        IOrganization,
    } from '@/types';

    @Component<OrganizationAddress>({
        components: {
            HereMap,
        },
    })

    export default class OrganizationAddress extends Vue {

        public isCopied: boolean = false;

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;

        public copyClipboard() {
            const copyText = this.organization.address?.label;

            navigator.clipboard.writeText(copyText as string).then(() => {
                this.isCopied = true;
            }, (err) => {
                this.isCopied = false;
            });

            setTimeout(() => {
                if (this.isCopied) {
                    this.isCopied = false;
                }
            }, 3000);
        }

    }
