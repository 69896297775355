
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import {
        IOrganization,
    } from '@/types';

    @Component<OrganizationOpening>({})
    export default class OrganizationOpening extends Vue {
        public showHours: boolean = false;

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;

        get currentDay() {
            const d = new Date();
            return (d.getDay() + 6) % 7;
        }

        public handlerShowHours() {
            this.showHours = !this.showHours;
        }
    }
